import TimeZoneSelect from '@/components/time-zone-select.view';
import { ILocale } from '@/constants/locales';
import { TTZ } from '@/constants/queryParams';
import { useSearchParams } from '@/hooks/useSearchParams';
import calendarService from '@/services/calendar/calendar.service';
import currencyFormatter from '@/services/currency-formatter';
import { IMonetaryAmount } from '@/types/common/imonetary-amount';
import ITimeZone from '@/types/ITimeZone';
import { Box, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

export interface IDateConfirmProps {
	start?: Date;
	end?: Date;
	timeZone: ITimeZone;
	eventTypeTimezone: ITimeZone;
	locale: ILocale;
	price?: IMonetaryAmount;
	textColor: 'white' | 'black';
}

export default function DateInfo(props: IDateConfirmProps) {
	const history = useHistory();
	const search = useSearchParams();
	const { t } = useTranslation();

	function handleTimeZoneChange(e: React.ChangeEvent<HTMLSelectElement>) {
		const value = e.target.value;
		search.set(TTZ, value);
		history.replace({ search: search.toString() });
	}

	if (!props.start || !props.end) {
		return null;
	}

	return (
		<Box bg="primary.500" py="4" px="4" borderRadius="md">
			<Text color={props.textColor} fontSize="lg" fontWeight="semibold">
				{calendarService.formatDate({
					date: props.start,
					locale: props.locale,
					options: {
						day: 'numeric',
						month: 'long',
						timeZone: props.timeZone,
						weekday: 'long',
					},
				})}
			</Text>
			<Text color={props.textColor} fontSize="xl" fontWeight="bold">
				{calendarService.formatDate({
					date: props.start,
					locale: props.locale,
					options: {
						hour: '2-digit',
						minute: '2-digit',
						timeZone: props.timeZone,
					},
				})}{' '}
				-{' '}
				{calendarService.formatDate({
					date: props.end,
					locale: props.locale,
					options: {
						hour: '2-digit',
						minute: '2-digit',
						timeZone: props.timeZone,
					},
				})}
			</Text>

			<TimeZoneSelect
				aria-label="TimeZone"
				fontWeight={500}
				color={props.textColor}
				mx="none"
				value={props.timeZone}
				onChange={handleTimeZoneChange}
				variant="unstyled"
			/>

			{props.eventTypeTimezone && props.eventTypeTimezone !== props.timeZone ? (
				<Box mt={4} color={props.textColor}>
					<Text fontWeight={500} fontSize="sm">
						{t('Host timezone')}:
					</Text>
					<Text fontSize="xs">{props.eventTypeTimezone}</Text>
				</Box>
			) : null}

			{props.price ? (
				<Box mt={4} color={props.textColor}>
					<Text fontWeight={600} fontSize="md">
						{currencyFormatter.format(props.price)}
					</Text>
				</Box>
			) : null}
		</Box>
	);
}
