import IApplicationConfig from '@/types/IApplicationConfig';

const dummyApplicationConfig: IApplicationConfig = {
	name: 'TimeTime',
	hasGoogleLogin: true,
	hasMicrosoftLogin: true,
	hasEmailAndPasswordLogin: true,
	hasGoogleCalendar: true,
	hasMicrosoftCalendar: true,
	theme: {
		ttColor: 'pink',
	},
	eventTypes: {
		redirectAfterBooking: {
			mode: 'W',
			defaultValue: '',
		},
		bookingMode: {
			mode: 'W',
			defaultValue: {
				type: 'InstantBookingMode',
			},
		},
		reminders: {
			createOptions: {
				options: {
					sms: true,
					webhook: true,
					whatsapp: true,
				},
			},
		},
	},
};

export default dummyApplicationConfig;
