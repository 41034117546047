import { ILocale } from '@/constants/locales';
import calendarService from '@/services/calendar/calendar.service';
import currencyFormatter from '@/services/currency-formatter';
import gridService from '@/services/grid.service';
import ITimeZone from '@/types/ITimeZone';
import ISlot from '@/types/Slot';
import { Box, Button, Tag, Text } from '@chakra-ui/react';
import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';
import GridHeader from './components/week-grid-desktop-header.view';
import DesktopGridNavigation from './components/week-grid-desktop-navigation';

export interface IGridBodyProps {
	onNextClick: () => void;
	onPrevClick: () => void;
	onSlotClick: (s: ISlot) => void;
	onUnitsChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
	displayOnlyStart: boolean;
	from: DateTime;
	locale: ILocale;
	maxUnitsPerBooking?: number;
	slots: ISlot[];
	timeZone: ITimeZone;
	unitsLabel?: string;
	value?: number;
}

export default function WeekGridDesktop(props: IGridBodyProps) {
	const { t } = useTranslation();
	const grid = gridService.organiseSlots(props.slots || [], props.timeZone);

	return (
		<Box display="grid" gridTemplateRows="auto auto 1fr" overflow="auto">
			<DesktopGridNavigation
				value={props.value}
				handleNextClick={props.onNextClick}
				handlePrevClick={props.onPrevClick}
				handleUnitsChange={props.onUnitsChange}
				maxUnitsPerBooking={props.maxUnitsPerBooking}
				unitsLabel={props.unitsLabel}
				from={props.from}
				locale={props.locale}
			/>

			<GridHeader locale={props.locale} grid={grid} timeZone={props.timeZone} />

			{grid.every((day) => day.every((slot) => slot.score === 0)) ? (
				<Box textAlign="center" mt="10">
					<Text fontSize="lg" fontWeight="600">
						{t('This date is fully booked')}
					</Text>
					<Text fontSize="sm" color="gray.500">
						{t('Please try selecting another date')}
					</Text>
				</Box>
			) : null}

			<Box overflow="auto" display="grid" gridTemplateColumns="repeat(7, auto)" pt={3}>
				{grid.map((day) => (
					<Box key={day[0].start.toISO()!} px={2} display="flex" flexDir="column" gap={2}>
						{day.map((slot) =>
							props.displayOnlyStart ? (
								<Button
									position="relative"
									fontSize="sm"
									key={slot.start.toISO()!}
									display="flex"
									isDisabled={slot.score === 0}
									py={6}
									fontWeight="400"
									onClick={() => props.onSlotClick(slot)}
								>
									{slot.price ? (
										<Tag variant="solid" colorScheme="primary" position="absolute" top={-1} right={-1} size="sm">
											{currencyFormatter.format(slot.price)}
										</Tag>
									) : null}
									{calendarService.formatDate({
										date: slot.start.toJSDate(),
										locale: props.locale,
										options: {
											hour: '2-digit',
											minute: '2-digit',
											timeZone: props.timeZone,
										},
									})}
								</Button>
							) : (
								<Button
									position="relative"
									fontSize="xx-small"
									key={slot.start.toISO()!}
									display="flex"
									isDisabled={slot.score === 0}
									py={6}
									fontWeight="400"
									onClick={() => props.onSlotClick(slot)}
								>
									{slot.price ? (
										<Tag variant="solid" colorScheme="primary" position="absolute" top={-1} right={-1} size="sm">
											{currencyFormatter.format(slot.price)}
										</Tag>
									) : null}
									{calendarService
										.formatDate({
											date: slot.start.toJSDate(),
											locale: props.locale,
											options: {
												hour: '2-digit',
												minute: '2-digit',
												timeZone: props.timeZone,
											},
										})
										.replace(/ AM| PM/, '')}{' '}
									-{' '}
									{calendarService.formatDate({
										date: slot.end.toJSDate(),
										locale: props.locale,
										options: {
											hour: '2-digit',
											minute: '2-digit',
											timeZone: props.timeZone,
										},
									})}
								</Button>
							)
						)}
					</Box>
				))}
			</Box>
		</Box>
	);
}
