import IApplicationConfig from '@/types/IApplicationConfig';

export const BASE_APP_CONFIG: IApplicationConfig = {
	name: 'TimeTime.in',
	hasEmailAndPasswordLogin: true,
	hasGoogleLogin: true,
	hasMicrosoftLogin: true,
	hasGoogleCalendar: true,
	hasMicrosoftCalendar: true,
	hideSidebar: false,
	hideTopBar: false,
	BookingConfirmationPage: {
		hideBranding: false,
	},
	BookingFormPage: {
		hideBranding: false,
	},
	LoginPage: {
		imageUrl: '',
	},
	theme: {
		ttColor: 'primary',
	},
	eventTypes: {
		bookingMode: {
			defaultValue: {
				type: 'InstantBookingMode',
			},
			mode: 'W',
		},
		redirectAfterBooking: {
			defaultValue: '',
			mode: 'W',
		},
		reminders: {
			createOptions: {
				options: {
					sms: true,
					webhook: true,
					whatsapp: true,
				},
			},
		},
	},
};

export function parseConfigurationFromQueryParams(
	baseConfig: IApplicationConfig,
	params: URLSearchParams
): IApplicationConfig {
	const config = structuredClone(baseConfig);

	const ttHideToolbar = params.get('tt_hide_sidebar');

	if (ttHideToolbar === 'true') {
		config.hideSidebar = true;
	}

	const ttHideTopBar = params.get('tt_hide_top_bar');

	if (ttHideTopBar === 'true') {
		config.hideTopBar = true;
	}

	const name = params.get('tt_app_name');

	if (name) {
		config.name = name;
	}

	const themeColor = params.get('tt_theme_color');

	if (themeColor) {
		config.theme.ttColor = themeColor;
	}

	return config;
}

function parseFromServerJSON(serverConfig: unknown): IApplicationConfig {
	const config = structuredClone(BASE_APP_CONFIG);

	if (typeof serverConfig !== 'object' || !serverConfig) {
		return config;
	}

	const safeConfig = serverConfig as Partial<IApplicationConfig>;

	if (typeof safeConfig.name === 'string') {
		config.name = safeConfig.name;
	}

	if (typeof safeConfig.hasEmailAndPasswordLogin === 'boolean') {
		config.hasEmailAndPasswordLogin = safeConfig.hasEmailAndPasswordLogin;
	}

	if (typeof safeConfig.hasGoogleLogin === 'boolean') {
		config.hasGoogleLogin = safeConfig.hasGoogleLogin;
	}

	if (typeof safeConfig.hasMicrosoftLogin === 'boolean') {
		config.hasMicrosoftLogin = safeConfig.hasMicrosoftLogin;
	}

	if (typeof safeConfig.hasGoogleCalendar === 'boolean') {
		config.hasGoogleCalendar = safeConfig.hasGoogleCalendar;
	}

	if (typeof safeConfig.hasMicrosoftCalendar === 'boolean') {
		config.hasMicrosoftCalendar = safeConfig.hasMicrosoftCalendar;
	}

	if (typeof safeConfig.hideSidebar === 'boolean') {
		config.hideSidebar = safeConfig.hideSidebar;
	}

	if (typeof safeConfig.hideTopBar === 'boolean') {
		config.hideTopBar = safeConfig.hideTopBar;
	}

	if (safeConfig.BookingConfirmationPage?.hideBranding !== undefined) {
		config.BookingConfirmationPage = {
			...config.BookingConfirmationPage,
			hideBranding: safeConfig.BookingConfirmationPage.hideBranding,
		};
	}

	if (safeConfig.BookingFormPage?.hideBranding !== undefined) {
		config.BookingFormPage = { ...config.BookingFormPage, hideBranding: safeConfig.BookingFormPage.hideBranding };
	}

	if (typeof safeConfig.LoginPage?.imageUrl === 'string') {
		config.LoginPage = { ...config.LoginPage, imageUrl: safeConfig.LoginPage.imageUrl };
	}

	if (typeof safeConfig.theme?.ttColor === 'string') {
		config.theme.ttColor = safeConfig.theme.ttColor;
	}

	if (safeConfig.eventTypes?.bookingMode?.defaultValue) {
		config.eventTypes.bookingMode.defaultValue = safeConfig.eventTypes.bookingMode.defaultValue;
	}

	if (safeConfig.eventTypes?.bookingMode?.mode) {
		config.eventTypes.bookingMode.mode = safeConfig.eventTypes.bookingMode.mode;
	}

	if (safeConfig.eventTypes?.redirectAfterBooking?.defaultValue !== undefined) {
		config.eventTypes.redirectAfterBooking.defaultValue = safeConfig.eventTypes.redirectAfterBooking.defaultValue;
	}

	if (safeConfig.eventTypes?.redirectAfterBooking?.mode) {
		config.eventTypes.redirectAfterBooking.mode = safeConfig.eventTypes.redirectAfterBooking.mode;
	}

	if (typeof safeConfig.eventTypes?.reminders?.createOptions?.options?.sms === 'boolean') {
		config.eventTypes.reminders.createOptions.options.sms = safeConfig.eventTypes.reminders.createOptions.options.sms;
	}

	if (typeof safeConfig.eventTypes?.reminders?.createOptions?.options?.webhook === 'boolean') {
		config.eventTypes.reminders.createOptions.options.webhook =
			safeConfig.eventTypes.reminders.createOptions.options.webhook;
	}

	if (typeof safeConfig.eventTypes?.reminders?.createOptions?.options?.whatsapp === 'boolean') {
		config.eventTypes.reminders.createOptions.options.whatsapp =
			safeConfig.eventTypes.reminders.createOptions.options.whatsapp;
	}

	return config;
}

export default {
	parseConfigurationFromQueryParams,
	parseFromServerJSON,
};
