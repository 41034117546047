import { MIN_SLOT_SCORE } from '@/constants/constants';
import { ILocale } from '@/constants/locales';
import calendarService from '@/services/calendar/calendar.service';
import currencyFormatter from '@/services/currency-formatter';
import dateService from '@/services/date/date.service';
import ITimeZone from '@/types/ITimeZone';
import ISlot from '@/types/Slot';
import { Box, Button, Tag, Text } from '@chakra-ui/react';
import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';
import WeekGridDayView from './components/week-grid-day-header.view';

export interface IGridMobileProps {
	onDayClick: (v: DateTime) => void;
	onNextClick: () => void;
	onPrevClick: () => void;
	onSlotClick: (s: ISlot) => void;
	onUnitsChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
	from: DateTime;
	locale: ILocale;
	maxUnitsPerBooking?: number;
	selectedDay: DateTime;
	slots: Array<ISlot>;
	timeZone: ITimeZone;
	unitsLabel?: string;
	value?: number;
	displayOnlyStart: boolean;
}

export default function WeekGridMobile(props: IGridMobileProps) {
	const { t } = useTranslation();
	const filteredSlots = props.slots.filter((slot) => {
		if (slot.score < MIN_SLOT_SCORE) {
			return false;
		}
		return dateService.isSameDay(slot.start.toJSDate(), props.selectedDay.toJSDate(), props.timeZone);
	});

	return (
		<Box h="full" overflow="auto" display="grid" gridTemplateRows="auto 1fr">
			<WeekGridDayView
				from={props.from}
				locale={props.locale}
				maxUnitsPerBooking={props.maxUnitsPerBooking}
				onDayChange={props.onDayClick}
				onNextClick={props.onNextClick}
				onPrevClick={props.onPrevClick}
				onUnitsChange={props.onUnitsChange}
				selectedDay={props.selectedDay}
				timeZone={props.timeZone}
				unitsLabel={props.unitsLabel}
				value={props.value}
			/>

			<Box display="grid" gap={3} p={4} px={8} overflow="auto" alignContent="start">
				{filteredSlots.length === 0 ? <Text textAlign="center">{t('This date is fully booked')}</Text> : null}

				{filteredSlots.map((slot) => {
					return props.displayOnlyStart ? (
						<Button
							position="relative"
							key={slot.start.toISO()}
							colorScheme="primary"
							variant="outline"
							onClick={() => {
								props.onSlotClick(slot);
							}}
						>
							{slot.price ? (
								<Tag variant="solid" colorScheme="primary" position="absolute" top={2.5} right={2} size="sm">
									{currencyFormatter.format(slot.price)}
								</Tag>
							) : null}
							{calendarService.formatDate({
								date: slot.start.toJSDate(),
								locale: props.locale,
								options: { hour: '2-digit', minute: '2-digit', timeZone: props.timeZone },
							})}
						</Button>
					) : (
						<Button
							position="relative"
							key={slot.start.toISO()}
							colorScheme="primary"
							variant="outline"
							onClick={() => {
								props.onSlotClick(slot);
							}}
						>
							{slot.price ? (
								<Tag variant="solid" colorScheme="primary" position="absolute" top={2.5} right={2} size="sm">
									{currencyFormatter.format(slot.price)}
								</Tag>
							) : null}
							{calendarService
								.formatDate({
									date: slot.start.toJSDate(),
									locale: props.locale,
									options: { hour: '2-digit', minute: '2-digit', timeZone: props.timeZone },
								})
								.replace(/ AM| PM/, '')}{' '}
							-{' '}
							{calendarService.formatDate({
								date: slot.end.toJSDate(),
								locale: props.locale,
								options: { hour: '2-digit', minute: '2-digit', timeZone: props.timeZone },
							})}
						</Button>
					);
				})}
			</Box>
		</Box>
	);
}
